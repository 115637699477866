import { Tooltip } from '@/components/ui/tooltip';
import { Text } from '@chakra-ui/react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

/**
 * Help icon with tooltip.
 */
export function Help({ help, size = 'md' }: { help?: React.ReactNode; size?: 'sm' | 'md' | 'lg' }) {
    if (!help) return null;

    let fontSize = 18;
    switch (size) {
        case 'sm':
            fontSize = 14;
            break;
        case 'md':
            fontSize = 16;
            break;
        case 'lg':
            fontSize = 24;
            break;
    }

    return (
        <Tooltip openDelay={0} showArrow content={help} positioning={{ placement: 'top' }}>
            <Text cursor="pointer" fontSize={fontSize} p={0} mr={1.5} color="gray.400" as="span">
                <AiOutlineQuestionCircle />
            </Text>
        </Tooltip>
    );
}
