import { Checkbox as ChakraCheckbox, CheckboxProps as ChakraCheckboxProps } from '@/components/ui/checkbox';
import { Field, FieldProps } from '@/components/ui/field';
import { InputGroup } from '@/components/ui/input-group';
import { Tooltip } from '@/components/ui/tooltip';
import {
    Card as ChakraCard,
    CardBodyProps as ChakraCardBodyProps,
    CardFooterProps as ChakraCardFooterProps,
    CardRootProps as ChakraCardRootProps,
    Heading as ChakraHeading,
    CheckboxGroupProps,
    Fieldset,
    Flex,
    IconButton,
    Input,
    InputProps,
    Separator,
    Text,
    TextProps,
} from '@chakra-ui/react';
import { Placement } from '@floating-ui/dom';
import * as React from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Help } from './Help';

interface RootProps {
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    shouldReset?: boolean;
    setShouldReset?: (value: boolean) => void;
    id?: string;
}
function Root({ children, onSubmit, shouldReset, setShouldReset, id }: React.PropsWithChildren<RootProps>) {
    const [resetCounter, setResetCounter] = React.useState(0);

    // resets entire form via react's key diff check
    // we don't have to worry about timing of children resetting etc.
    React.useEffect(() => {
        if (!shouldReset || !setShouldReset) {
            return;
        }

        setShouldReset(false);
        setResetCounter((resetCounter) => {
            return resetCounter + 1;
        });
    }, [shouldReset, setShouldReset]);

    return (
        <form key={resetCounter} onSubmit={onSubmit} id={id}>
            {children}
        </form>
    );
}

function Card({ children, ...props }: React.PropsWithChildren<ChakraCardRootProps>) {
    return (
        <ChakraCard.Root display="flex" direction="column" width="full" {...props}>
            {children}
        </ChakraCard.Root>
    );
}

function CardBody({ children, ...props }: React.PropsWithChildren<ChakraCardBodyProps>) {
    return (
        <ChakraCard.Body {...props}>
            <Flex direction="column" gap="1rem">
                {children}
            </Flex>
        </ChakraCard.Body>
    );
}

function CardFooter({ children, ...props }: React.PropsWithChildren<ChakraCardFooterProps>) {
    return <ChakraCard.Footer {...props}>{children}</ChakraCard.Footer>;
}

function Heading({ children }: React.PropsWithChildren) {
    return (
        <ChakraHeading
            as="h2"
            lineHeight="1.56"
            fontWeight="600"
            fontSize="1.125rem"
            color="gray.900"
            letterSpacing="normal"
            width="full"
        >
            {children}
        </ChakraHeading>
    );
}
interface ItemProps {
    id: string;
    label?: React.ReactNode;
    shouldShowSeparator?: boolean;
    help?: React.ReactNode;
}
function HItem({
    id,
    label,
    children,
    shouldShowSeparator = false,
    ...props
}: React.PropsWithChildren<ItemProps & FieldProps>) {
    return (
        <>
            <Field
                id={id}
                display="flex"
                orientation="horizontal"
                justifyContent="space-between"
                alignItems="start"
                minHeight="40px"
                gap="150px"
                label={label}
                {...props}
            >
                <Flex direction="column" gap="0.25rem" w="full" alignSelf="center">
                    {children}
                </Flex>
            </Field>
            {shouldShowSeparator ? <Separator orientation="horizontal" /> : null}
        </>
    );
}

function VItem({
    id,
    label,
    children,
    shouldShowSeparator = false,
    help,
    ...props
}: React.PropsWithChildren<ItemProps & FieldProps>) {
    return (
        <>
            <Field
                labelWidth={help ? 'full' : undefined}
                width="full"
                id={id}
                label={
                    (label || help) && (
                        <>
                            <Flex w="full" justifyContent="space-between" alignContent="center">
                                {label}
                                <Help help={help} />
                            </Flex>
                        </>
                    )
                }
                {...props}
            >
                {children}
            </Field>

            {shouldShowSeparator ? <Separator orientation="horizontal" /> : null}
        </>
    );
}

function CtInput({ value: initialValue, onChange, ...props }: InputProps) {
    const [value, setValue] = React.useState(() => initialValue || '');

    return (
        <Input
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
                onChange?.(e);
            }}
            {...props}
        />
    );
}

// customized input handles read only styles, ellipses and adds a tooltip
interface CtInputCustomProps extends InputProps {
    showEllipses?: boolean;
    tooltipPlacement?: Placement;
}
function CtInputCustom({
    value,
    onChange,
    readOnly,
    showEllipses = true,
    tooltipPlacement = 'bottom-start',
    ...props
}: CtInputCustomProps) {
    return (
        <Tooltip showArrow positioning={{ placement: tooltipPlacement }} content={value}>
            <Input
                value={value}
                onChange={onChange}
                readOnly={readOnly || !onChange || false}
                {...(readOnly
                    ? {
                          color: 'gray.400',
                          borderColor: 'gray.200',
                          cursor: 'not-allowed',
                          _hover: { borderColor: 'gray.200' },
                          _focus: {
                              borderColor: 'gray.200',
                              boxShadow: 'none',
                          },
                      }
                    : {})}
                {...(showEllipses
                    ? {
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                      }
                    : {})}
                {...props}
            />
        </Tooltip>
    );
}

function InputPassword({ placeholder, ...props }: InputProps) {
    const [shouldShow, setShouldShow] = React.useState(() => false);

    const hidePasswordIconStyle = `
        input::-ms-reveal,
        input::-ms-clear {
            display: none;
        }
    `;

    return (
        <InputGroup
            w="full"
            endElement={
                <IconButton
                    onClick={() => {
                        setShouldShow((show) => !show);
                    }}
                    aria-label="show password"
                    color="gray.500"
                    background="transparent"
                >
                    {shouldShow ? <FiEye /> : <FiEyeOff />}
                </IconButton>
            }
        >
            <>
                <style>{hidePasswordIconStyle}</style>
                <CtInput
                    type={shouldShow ? 'text' : 'password'}
                    placeholder={placeholder ?? 'Enter password'}
                    {...props}
                />
            </>
        </InputGroup>
    );
}

function InputError({ children, ...props }: React.PropsWithChildren<TextProps>) {
    return children ? (
        <Text color="red.600" fontSize="small" {...props}>
            {children}
        </Text>
    ) : null;
}

function InputWarning({ children }: React.PropsWithChildren) {
    return children ? (
        <Text color="orange.600" fontSize="small">
            {children}
        </Text>
    ) : null;
}

function CheckboxGroup({ children, ...props }: CheckboxGroupProps, label: string) {
    return (
        <Fieldset.Root>
            <CheckboxGroup {...props}>
                <Fieldset.Legend fontSize="sm" mb="1rem">
                    {label}
                </Fieldset.Legend>
                <Fieldset.Content>{children}</Fieldset.Content>
            </CheckboxGroup>
        </Fieldset.Root>
    );
}

function Checkbox({ children, ...props }: ChakraCheckboxProps) {
    return (
        <ChakraCheckbox fontWeight="400" size="lg" gap="8px" {...props}>
            <Text fontWeight="400">{children}</Text>
        </ChakraCheckbox>
    );
}

export const CtForm = Object.assign(Root, {
    Card,
    CardBody,
    CardFooter,
    Heading,
    HItem,
    VItem,
    Input: CtInput,
    InputCustom: CtInputCustom,
    InputPassword,
    InputError,
    InputWarning,
    CheckboxGroup,
    Checkbox,
});
