import { Field } from '@/components/ui/field';
import { Input, InputProps } from '@chakra-ui/react';
import { CtForm } from '@common/CtForm';
import * as React from 'react';

interface EmailProps {
    emailIsValid: boolean;
    setEmail: (email: string) => void;
}

export const Email: React.FC<EmailProps & InputProps> = ({ emailIsValid, setEmail, ...rest }) => {
    return (
        <Field label="Email" invalid={!emailIsValid} errorText="Email is invalid">
            <Input
                {...rest}
                type="email"
                placeholder={emailIsValid ? '' : 'Invalid Email'}
                onChange={(evt) => setEmail(evt.target.value)}
            />
        </Field>
    );
};

interface PasswordProps {
    placeholder?: string;
    passwordIsValid: boolean;
    setPassword: (password: string) => void;
    validateStrength?: boolean;
}

export const Password: React.FC<PasswordProps & InputProps> = ({
    placeholder,
    passwordIsValid,
    setPassword,
    validateStrength,
    ...rest
}) => {
    return (
        <CtForm.VItem id="password" label="Password" invalid={!passwordIsValid} required>
            <CtForm.InputPassword
                {...rest}
                placeholder={placeholder}
                onChange={(evt) => setPassword(evt.target.value)}
            />
            {passwordIsValid ? null : (
                <CtForm.InputError>
                    {validateStrength
                        ? 'Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
                        : 'Please enter a minimum of 10 characters.'}
                </CtForm.InputError>
            )}
        </CtForm.VItem>
    );
};

interface ConfirmPasswordProps {
    placeholder?: string;
    currentPassword: string;
    setPassword: (password: string) => void;
}

export const ConfirmPassword: React.FC<ConfirmPasswordProps & InputProps> = ({
    placeholder,
    setPassword,
    currentPassword,
    ...rest
}) => {
    const [confirmPassword, setPasswordConfirm] = React.useState('');
    const isValid = currentPassword === confirmPassword;
    const [hasBeenFocused, setHasBeenFocused] = React.useState(false);

    return (
        <CtForm.VItem id="confirmPassword" label="Confirm password" invalid={!isValid && hasBeenFocused} required>
            <CtForm.InputPassword
                {...rest}
                onFocus={() => setHasBeenFocused(true)}
                placeholder={placeholder}
                onChange={(evt) => {
                    setPasswordConfirm(evt.target.value);
                    setPassword(evt.target.value);
                }}
            />
            {!isValid && hasBeenFocused && (
                <CtForm.InputError>Passwords do not match. Please try again.</CtForm.InputError>
            )}
        </CtForm.VItem>
    );
};

interface UsernameProps {
    usernameIsValid: boolean;
    setUsername: (username: string) => void;
}

export const Username: React.FC<UsernameProps & InputProps> = ({ usernameIsValid, setUsername, ...rest }) => {
    return (
        <CtForm.VItem id="username" label="Email" invalid={!usernameIsValid} required>
            <CtForm.Input
                {...rest}
                type="email"
                placeholder="Enter email"
                onChange={(evt) => {
                    setUsername(evt.target.value?.toLowerCase()?.trim());
                }}
            />
            {usernameIsValid ? null : <CtForm.InputError>Please enter a valid email.</CtForm.InputError>}
        </CtForm.VItem>
    );
};

interface CodeProps {
    codeIsValid: boolean;
    setCode: (code: string) => void;
}

export const Code: React.FC<CodeProps & InputProps> = ({ codeIsValid, setCode, ...rest }) => {
    return (
        <Field label="Code" required invalid={!codeIsValid} errorText="Code is invalid">
            <Input
                {...rest}
                type="text"
                placeholder={!codeIsValid ? 'Minimum 6 characters' : ''}
                onChange={(evt) => setCode(evt.target.value.trim())}
            />
        </Field>
    );
};

interface RequiredTextFieldProps {
    isValid: boolean;
    setField: (field: string) => void;
    label: string;
    name: string;
    placeholder?: string;
    errorMessage: string;
}

export const RequiredTextField: React.FC<RequiredTextFieldProps & InputProps> = ({
    isValid,
    setField,
    label,
    name,
    placeholder,
    errorMessage,
    ...rest
}) => {
    return (
        <CtForm.VItem id={name} label={label} invalid={!isValid} required>
            <CtForm.Input
                {...rest}
                type="text"
                placeholder={placeholder ?? ''}
                onChange={(evt) => setField(evt.target.value)}
            />
            {isValid ? null : <CtForm.InputError>{errorMessage}</CtForm.InputError>}
        </CtForm.VItem>
    );
};
